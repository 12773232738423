'use strict';

import $ from 'jquery';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import page from 'page';
import FirebaseHelper from './FirebaseHelper';
import LoadImage from './LoadImage';

/**
 * Handles the pages/routing.
 */
export default class Router {
  /**
   * Inicializa o controller/router do Portal MaisFuturo.
   * @constructor
   */
  constructor(auth) {
    this.authCl = auth
    this.auth = firebase.auth()
    this.firebaseHelper = new FirebaseHelper();
    this.loadImage = new LoadImage();
    this.pagesElements = $('[id^=page-]');
    // Carrega metodos gerais dos JS.
    const loadComponents = import(/* webpackPrefetch: true */ './async-loaded-components');
    //const loadUser = async (userId) => (await loadComponents).userPage.loadUser(userId);
    const showHome = async () => (await loadComponents).home.showHome();
    const verificaPrimeiroLogin = async () => (await loadComponents).primeiroLogin.verificaPrimeiroLogin();
    const telaPrimeiroLoginConfig = async () => (await loadComponents).primeiroLogin.telaPrimeiroLoginConfig();
    const aguardaValidaLinkPrimeiroLogin = async () => (await loadComponents).primeiroLogin.aguardaValidaLinkPrimeiroLogin();
    const telaConfirmacaoDadosFinalConfig = async () => (await loadComponents).primeiroLogin.telaConfirmacaoDadosFinalConfig();
    // Configuring middlwares.
    page(Router.setLinkAsActive);
    // Configuring routes.
    page('/', async () => {
      if (localStorage.isPwaInstalled === "true" || localStorage.standaloneDetected === "true" || localStorage.isMac === "true") {
        let imagem = await this.loadImage.carregarImagemApp("logo_colorida_512x512")
        let img = document.getElementById("img-splash-login")
        img.setAttribute("src", imagem.url);
        img.setAttribute("class", "page-splash_img");
        //this.displayPage('splash-login')
        this.redirectHomeIfSignedIn()
      }
    });
    page('/home', async () => {
      verificaPrimeiroLogin().then((primeiroLogin) => {
        if (primeiroLogin) {
          telaPrimeiroLoginConfig()
          this.displayPage('primeiro-login');
        } else {
          showHome().then(() => {
            this.displayPage('home')
          })
        }
      }).catch((e) => {
        console.log('Erro!', e)
      })
    });
    page('/signout', async () => {
      let imagem = await this.loadImage.carregarImagemApp("logo_colorida_512x512")
      let img = document.getElementById("img-splash-login")
      img.setAttribute("src", imagem.url);
      img.setAttribute("class", "page-splash_img");
      this.displayPage('splash-login');
    });
    page('/confirmacao-dados', async () => {
      let imagem = await this.loadImage.carregarImagemApp("logo_colorida_210x70")
      let img = document.getElementById("img-confirmacao-dados")
      img.setAttribute("src", imagem.url);
      this.displayPage('confirmacao-dados')
    });
    page('/erro-confirmacao-dados', async () => {
      let imagem = await this.loadImage.carregarImagemApp("logo_colorida_210x70")
      let img = document.getElementById("img-erro-confirmacao-dados")
      img.setAttribute("src", imagem.url);
      let btnErroSair = document.querySelector('.fp-erro-sair')
      btnErroSair.addEventListener('click', e => { this.firebaseHelper.signOut() })
      this.displayPage('erro-confirmacao-dados')
    });
    page('/confirmacao-dados-final', async () => {
      let imagem = await this.loadImage.carregarImagemApp("logo_colorida_210x70")
      let img = document.getElementById("img-confirmacao-dados-final")
      img.setAttribute("src", imagem.url);
      telaConfirmacaoDadosFinalConfig()
      this.displayPage('confirmacao-dados-final')
      aguardaValidaLinkPrimeiroLogin()
    });
    page('/aviso-validacao', async () => {
      let imagem = await this.loadImage.carregarImagemApp("logo_colorida_210x70")
      let img = document.getElementById("img-aviso-validacao")
      img.setAttribute("src", imagem.url);
      this.displayPage('aviso-validacao')
      aguardaValidaLinkPrimeiroLogin()
    });
    page('*', () => {
      page('/')
    });

    page(); // Start routing.
  }

  /**
   * Faz o display da página
   * if `onlyAuthed` for setado como true a splash page será demonstrada ao invés da página
   * (para o caso do usuário não estar logado)
   * A "page" é o elemento com o ID "page-<id>" na DOM.
   */
  async displayPage(pageId) {
    //sessionStorage.paginaAtual = pageId
    this.pagesElements.each((index, element) => {
      if (element.id === 'page-' + pageId) {
        $('#'+element.id).show()
      } else {
        $('#'+element.id).hide()
      }
    });
    // Scroll to top.
    let pagina = document.querySelector(`#divFixedHeader`)
    if (pageId == 'historico-contribuicao' || pageId == 'historico-emprestimo') {
      pagina.scrollTop = 300
    } else {
      pagina.scrollTop = 0
    }
    if (pageId !== 'signout' && pageId !== 'home' && pageId.indexOf('splash') < 0) {
      firebase.analytics().logEvent('screen_view', { screen_name: pageId });
    }
  }
  /**
   * Redireciona para o home caso o usuário esteja logado.
   */
  async redirectHomeIfSignedIn() {
    if (firebase.auth().currentUser) {
      page('/home');
    }
  }
  /**
   * Reload da página atual
   */
  static reloadPage() {
    let path = window.location.pathname;
    let isReloading = sessionStorage.isReloading === "true"
    if (path === '' || path === '/') {
      page('/home');
    } else if (path === '/signout' || isReloading){
      //sessionStorage.clear()
      if (path === '/signout') {
        sessionStorage.participante = ""
      }
      sessionStorage.saveFotoBD = ""
      sessionStorage.historicoContribuicao = ""
      sessionStorage.historicoEmprestimo = ""
      sessionStorage.historicoBeneficio = ""
      sessionStorage.dadosSimuladorSeguro = ""
      sessionStorage.uid = ""
      sessionStorage.chave = ""
      sessionStorage.plano = ""
      sessionStorage.perfil_investimento = ""
      sessionStorage.dataUltimoLogin = ""
      sessionStorage.cpfPrincipal = ""
      sessionStorage.chavePrincipal = ""
      sessionStorage.dadosSimuladorEmprestimo = ""
      sessionStorage.settings_Imagens = ""
      sessionStorage.settings_dados_entidade = ""
      sessionStorage.sinqia = ""
      sessionStorage.cadastro_incompleto = ""
      sessionStorage.finalizandoCadastroAdesao = ""
      sessionStorage.avatar = ""

      if (path !== '/home') {
        if (sessionStorage.isReloading === "true" && path !== '/signout') {
          path = '/home'
          page(path);
        }
      }
    }
    sessionStorage.isReloading = "false"

  }

  /**
   * Scrolls the page to top.
   */
  static scrollToTop() {
    $('html,body').animate({scrollTop: 0}, 0);
  }

  /**
   * Page.js middleware that highlights the correct menu item/link.
   */
  static setLinkAsActive(context, next) {
    const canonicalPath = context.canonicalPath;
    if (canonicalPath === '') {
      canonicalPath = '/';
    }
    $('.is-active').removeClass('is-active');
    $(`[href="${canonicalPath}"]`).addClass('is-active');
    next();
  }

};
